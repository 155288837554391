import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SEO from '../../components/seo'
import Title from '../../components/title'
import Header from '../../components/header'
import Container from '../../components/container'
import FeatureBlock from '../../components/feature-block'
import Layout from '../../layout'
import Image from '../../components/image'
import Brands from '../../components/brands'
import AnimatedArticle from '../../components/animated-article'
import AnimatedAside from '../../components/animated-aside'
import P from '../../components/paragraph'
import BrandBadge from '../../components/brand-badge'

const QuintesscentPage = () => {
  const images = useStaticQuery(graphql`
    query {
      bottle: file(
        relativePath: { eq: "brands/quintesscent/quintesscent-bottle.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 770) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      group: file(
        relativePath: { eq: "brands/quintesscent/quintesscent-group.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title='Quintesscent'
        description={`A scent is a time capsule which can evoke our most intimate memories and dreams. It can open doors to distant worlds. Quintesscent Purfumes is a luxury British fine fragrance brand. Evoking an emotional connection to our memories through the complexity of beautifully crafted scents which capture the essence of the myriad of encounters we have with life through our emotions and memories.`}
      />
      <Header />

      <Container wide noPadding>
        <FeatureBlock>
          <AnimatedArticle onLoad badge>
            <Title fancy>Quintesscent</Title>
            <P>
              A scent is a time capsule which can evoke our most intimate
              memories and dreams. It can open doors to distant worlds.
              Quintesscent Purfumes is a luxury British fine fragrance brand.
              Evoking an emotional connection to our memories through the
              complexity of beautifully crafted scents which capture the essence
              of the myriad of encounters we have with life through our emotions
              and memories.
            </P>
            <BrandBadge brand='quintesscent' />
          </AnimatedArticle>
          <AnimatedAside>
            <Image
              css={`
                @media (min-width: ${({ theme }) => theme.breakpoint.large}) {
                  margin-top: 100px;
                }
              `}
              fluid={images.bottle.childImageSharp.fluid}
              notch='bottomLeftSmall'
              alt='Quintesscent bottle'
            />
          </AnimatedAside>
        </FeatureBlock>
      </Container>

      <Container width={1100} noPadding overflow css='margin-top: -50px'>
        <FeatureBlock grey={false} center>
          <AnimatedAside right>
            <Image
              fluid={images.group.childImageSharp.fluid}
              shadow={false}
              alt='Perfume bottle'
              css={`
                max-width: 600px;
                @media (max-width: ${({ theme }) => theme.breakpoint.large}) {
                  max-width: 300px;
                  margin: 0 auto;
                }
              `}
            />
          </AnimatedAside>
          <AnimatedArticle>
            <P>
              The sense of smell is one of the most powerful senses we possess
              and the merest hint of something familiar can trigger a memory of
              a wonderful moment that we will treasure forever. Our scents will
              take you on a thought provoking and memorable journey.
            </P>
          </AnimatedArticle>
        </FeatureBlock>
      </Container>

      <Container width={1200}>
        <Title fancy>Other Brands</Title>
        <Brands />
      </Container>
    </Layout>
  )
}

export default QuintesscentPage
